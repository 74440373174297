export function randomString (length) {
  var possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var text = possible.charAt(Math.floor(Math.random() * 26));
  var i = 1;
  while (i < length) {
    text += possible.charAt(Math.floor(Math.random() * 36));
    i++;
  }
  return text;
}
