import { Controller } from '@hotwired/stimulus';
import { randomString } from '../lib/random_string';

export default class extends Controller {
  generate() {
    document.getElementById('application_slug').value = randomString(16);
  }
}


